import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      name: "code_1",
      onPaste: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      onInput: _cache[1] || (_cache[1] = ev => _ctx.handleFieldInput(1, ev)),
      onKeydown: _cache[2] || (_cache[2] = ev => _ctx.handleKeyDown(0, ev)),
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      ref: "input1",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544),
    _createElementVNode("input", {
      type: "text",
      name: "code_2",
      onPaste: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      ref: "input2",
      onKeydown: _cache[4] || (_cache[4] = ev => _ctx.handleKeyDown(1, ev)),
      onInput: _cache[5] || (_cache[5] = ev => _ctx.handleFieldInput(2, ev)),
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544),
    _createElementVNode("input", {
      type: "text",
      name: "code_3",
      onPaste: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      onInput: _cache[7] || (_cache[7] = ev => _ctx.handleFieldInput(3, ev)),
      onKeydown: _cache[8] || (_cache[8] = ev => _ctx.handleKeyDown(2, ev)),
      ref: "input3",
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544),
    _createElementVNode("input", {
      type: "text",
      name: "code_4",
      onPaste: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      onKeydown: _cache[10] || (_cache[10] = ev => _ctx.handleKeyDown(3, ev)),
      onInput: _cache[11] || (_cache[11] = ev => _ctx.handleFieldInput(4, ev)),
      ref: "input4",
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544),
    _createElementVNode("input", {
      type: "text",
      name: "code_5",
      onInput: _cache[12] || (_cache[12] = ev => _ctx.handleFieldInput(5, ev)),
      onPaste: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      onKeydown: _cache[14] || (_cache[14] = ev => _ctx.handleKeyDown(4, ev)),
      ref: "input5",
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544),
    _createElementVNode("input", {
      type: "text",
      onPaste: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
      name: "code_6",
      onKeydown: _cache[16] || (_cache[16] = ev => _ctx.handleKeyDown(5, ev)),
      ref: "input6",
      onInput: _cache[17] || (_cache[17] = ev => _ctx.handleFieldInput(6, ev)),
      "data-inputmask": "'mask': '9', 'placeholder': ''",
      maxlength: "1",
      class: "form-control bg-transparent h-md-60 w-md-60 fs-2qx text-center mx-1 my-2",
      value: ""
    }, null, 544)
  ]))
}