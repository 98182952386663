
import { defineComponent, ref } from "vue";
export default defineComponent({
  emits: ["code-ready"],
  name: "otp-code-input",
  setup: function(props, { emit }) {
    const input1 = ref<HTMLInputElement>();
    const input2 = ref<HTMLInputElement>();
    const input3 = ref<HTMLInputElement>();
    const input4 = ref<HTMLInputElement>();
    const input5 = ref<HTMLInputElement>();
    const input6 = ref<HTMLInputElement>();
    const inputs = [input1, input2, input3, input4, input5, input6];

    function getValues(): string {
      return inputs
        .map(input => (input && input.value ? input.value.value : ""))
        .join("");
    }

    function focusInput(inputIndex: number): void {
      if (inputIndex < 0 || inputIndex > 5) {
        return;
      }
      const field = inputs[inputIndex];
      if (field) {
        field.value?.focus();
      }
    }

    function handlePaste(event): void {
      const clipboardData = event.clipboardData;
      const pastedData = clipboardData.getData("text");

      for (let i = 1; i <= Math.min(pastedData.length, inputs.length); i++) {
        const currentInput = inputs[i - 1];
        if (currentInput && currentInput.value) {
          currentInput.value.value = pastedData[i - 1];
        }
      }
      inputs[5].value?.focus();
      if (getValues().length == 6) {
        emit("code-ready", getValues());
      }
      event.preventDefault();
    }

    function handleFieldInput(index: number, event): void {
      if (index <= 5 && event.target.value) {
        inputs[index].value?.focus();
      }
      if (getValues().length == 6) {
        emit("code-ready", getValues());
      }
    }

    function handleKeyDown(index: number, event): void {
      if ("Backspace" === event.key) {
        if (0 !== index && index <= 6) {
          inputs[index - 1].value?.focus();
          event.srcElement.value = "";
        }
      }
    }

    return {
      handleFieldInput,
      handleKeyDown,
      handlePaste,
      focusInput,
      getValues,
      input1,
      input2,
      input3,
      input4,
      input5,
      input6
    };
  }
});
