<template>
  <div class="d-flex flex-column flex-root bg-dark" id="kt_app_root">
    <div class="d-flex flex-column flex-column-fluid flex-lg-row">
      <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div class="d-flex flex-center flex-lg-start flex-column">
          <a href="index.html" class="mb-7">
            <img alt="Logo" class="h-20px" src="/media/logos/logo.png" />
          </a>
          <i class="fas-solid fas-qrcode"></i>
          <h2 class="text-white fw-normal m-0">
            Enable Two-Factor Authentication (2FA) for your account
          </h2>
        </div>
      </div>
      <div
        class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-6 p-sm-12 p-lg-20"
      >
        <div
          class="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-5 p-sm-20 rounded-3"
        >
          <div
            class="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20"
          >
            <form
              class="form w-100 mb-13"
              novalidate="novalidate"
              v-if="step === 1"
            >
              <div class="text-center mb-10">
                <img
                  alt="Logo"
                  class="mh-125px"
                  src="/media/svg/misc/smartphone.svg"
                />
              </div>
              <div class="text-center mb-10">
                <h1 class="text-gray-900 mb-3">Scan QR code</h1>
                <div class="text-muted fw-semibold fs-5 mb-5">
                  To set up two-factor authentication, open the Google
                  Authenticator app on your phone, enter your code
                  <span class="text-dark fw-bold">{{ code }}</span
                  >, or scan the QR below.
                </div>
              </div>
              <div
                class="mb-10 d-flex align-items-center justify-content-center w1"
              >
                <div v-html="qrImage" class="text-center"></div>
              </div>
              <div class="d-flex flex-center">
                <button
                  type="button"
                  id="kt_sing_in_two_factor_submit"
                  class="btn btn-lg btn-primary fw-bold"
                  @click="handleContinueButton"
                >
                  <span class="indicator-label">Continue</span>
                  <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
            <form
              class="form w-100 mb-13"
              novalidate="novalidate"
              data-kt-redirect-url="index.html"
              id="kt_sing_in_two_factor_form"
              v-if="step === 2"
            >
              <div class="text-center mb-10">
                <img
                  alt="Logo"
                  class="mh-125px"
                  src="/media/svg/misc/smartphone.svg"
                />
              </div>
              <div class="text-center mb-10">
                <h1 class="text-gray-900 mb-3">Type Your Code</h1>
                <div class="text-muted fw-semibold fs-5 mb-5">
                  Type your 6 digit security code from the Google Authenticator
                  app on your phone
                </div>
              </div>
              <div class="mb-10">
                <div class="fw-bold text-start text-gray-900 fs-6 mb-1 ms-1">
                  Your 6 digit security code
                </div>
                <OtpCodeInput
                  @keydown.enter="handleSecurityCodeSubmit"
                  ref="otpCodeInput"
                />
              </div>
              <div class="d-flex flex-center">
                <button
                  :disabled="loading"
                  type="button"
                  id="kt_sing_in_two_factor_submit"
                  class="btn btn-lg btn-primary fw-bold"
                  @click="handleSecurityCodeSubmit()"
                >
                  <span class="indicator-label" v-if="!loading">Submit</span>
                  <span v-else
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
          </div>
          <div class="d-flex flex-stack px-lg-10">
            <div class="d-flex fw-semibold text-primary fs-base gap-5">
              <a href="pages/team.html" target="_blank">Terms</a>
              <a href="pages/pricing/column.html" target="_blank">Plans</a>
              <a href="pages/contact.html" target="_blank">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import OtpCodeInput from "@/components/otp/OtpCodeInput.vue";
import Swal from "sweetalert2";
import { Actions } from "@/store/enums/StoreEnums";
import { nextTick } from "vue";

export default {
  name: "enable-otp",
  components: { OtpCodeInput },
  data() {
    return {
      step: 1,
      loading: false
    };
  },
  methods: {
    ...mapActions("OtpModule", ["fetchQrImage"]),
    handleInput(nextInput) {
      if (nextInput < 6) {
        this.$refs[`input${nextInput + 1}`].focus();
      }
    },
    handleContinueButton() {
      this.step = 2;
      nextTick().then(() => {
        const otpCodeInput = this.$refs.otpCodeInput;
        if (undefined !== otpCodeInput) {
          otpCodeInput.focusInput(0);
        }
      });
    },
    handleSecurityCodeSubmit() {
      this.loading = true;
      this.$store
        .dispatch("OtpModule/store", this.$refs.otpCodeInput.getValues())
        .then(response => {
          if (response.status === 200) {
            this.loading = false;
            this.$store.dispatch(Actions.SET_FETCH_USER, true);
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            timer: 1500,
            title: "Invalid OTP code"
          });
        });
    }
  },
  computed: {
    qrImage() {
      return this.$store.state.OtpModule.qrImage;
    },
    code() {
      return this.$store.state.OtpModule.code;
    }
  },
  mounted() {
    this.fetchQrImage();
  }
};
</script>
